@import 'src/styles/variables';
@import 'src/styles/mixins';

.accordion {
  display: block;
  border-bottom: 1px solid rgba($color: $gray, $alpha: 0.3);
}

.title {
  font-weight: 700;
  color: $black;
  width: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 16px 0;
  cursor: pointer;
}

.icon {
  margin-left: auto;
}

.list {
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  padding-left: 16px;

  .title {
    @include text(14px, 400, 20px);
  }
}

.hidden {
  max-height: 0px;
  overflow: hidden;
  margin-top: 0;
}

div.divider {
  margin: 0;
}
